import { useContext } from 'react';

import { BookDefinitionContext } from '@/components/common/Book/BookDefinitionContext';
import { BreakPoint } from '@/components/styles/media';
import { modularComponent } from '@/utils/modularComponent';

import * as styles from './LandscapeBookMetadataRenderer.styles';

const landscapeCollapsePolicy = [
  { orBelow: BreakPoint.Large, value: true },
  { greaterThan: BreakPoint.Large, value: false },
];

export const LandscapeBookMetadataItems = modularComponent(() => ({ children, className }) => {
  const {
    BookAuthors,
    BookGenre,
    BookMetadataItemGroup: MetadataItemGroup,
    BookPublisher,
    BookSeries,
    BookStarRate,
  } = useContext(BookDefinitionContext).components;

  return (
    <MetadataItemGroup className={className} collapse={landscapeCollapsePolicy}>
      <BookAuthors />
      <BookStarRate />
      <MetadataItemGroup>
        <BookPublisher />
        <BookGenre />
      </MetadataItemGroup>
      <BookSeries />
      {children}
    </MetadataItemGroup>
  );
});

export const LandscapeBookMetadataRenderer = modularComponent(() => ({ children, index = 0 }) => {
  const {
    BookDescription,
    BookMetadataItems: MetadataItems,
    BookPrice,
    BookTitle,
  } = useContext(BookDefinitionContext).components;

  return (
    <>
      <BookTitle index={index} />
      <MetadataItems css={styles.landscapeMetadataRowStyle} />
      <BookDescription index={index} />
      <BookPrice />
      {children}
    </>
  );
});
