import { useContext } from 'react';

import { Author } from '@/base/interfaces/Author';
import { TrackClickEvent } from '@/components/common/EventClient/TrackClickEvent';
import { underlineHoverStyle } from '@/components/styles';
import { sortAuthors } from '@/utils/author';
import { EventParamsType, EventParamsValueType } from '@/utils/eventClient';
import { modularComponent, ModularResponsiveOption, modularResponsiveStyle } from '@/utils/modularComponent';

import { BookDataContext } from '../BookDataContext';
import { BookDefinitionContext } from '../BookDefinitionContext';
import { joinComponents } from '../utils/joinComponents';
import * as styles from './BookAuthors.styles';

export interface BookAuthorType extends Author {
  link?: string | null;
}

interface TrackingBookAuthorParams extends EventParamsType {
  book_id: EventParamsValueType;
  author_name: EventParamsValueType;
  author_id: EventParamsValueType;
}

interface BookAuthorItemProps {
  className?: string;
  author: BookAuthorType;
}

export const BookAuthorItem = modularComponent(() => ({ className, author }: BookAuthorItemProps) => {
  const bookData = useContext(BookDataContext);
  const sectionData = useContext(BookDefinitionContext);

  if (!author.name) {
    return <></>;
  }

  if (author.link === null) {
    return <span className={className}>{author.name}</span>;
  }

  let link;
  if (author.link) {
    link = author.link;
  } else if (author.id) {
    link = `/author/${author.id}`;
  } else {
    link = `/search?q=${encodeURIComponent(author.name)}`;
  }

  const linkContent = (
    <a className={className} href={link} css={underlineHoverStyle}>
      {author.name}
    </a>
  );

  if (!sectionData.trackingData || bookData.trackingOptions?.disableTrackEvent) {
    return linkContent;
  }

  const computedParams: TrackingBookAuthorParams = {
    book_id: bookData.bookId,
    author_name: author.name,
    author_id: author.id,
    ...sectionData.trackingData.params,
    ...bookData.trackingOptions?.extraParams,
  };
  return (
    <TrackClickEvent screenName={sectionData.trackingData.screenName} target="author" params={computedParams}>
      {linkContent}
    </TrackClickEvent>
  );
});

export const BookAuthors = modularComponent(
  ({ textSize, clipBreakPoint, clipCount }) => {
    const authorStyle = modularResponsiveStyle(styles.authorStyle, textSize);

    return ({ className }) => {
      const { BookAuthorItem: BookContextAuthorItem, BookDefaultMetadataItem } =
        useContext(BookDefinitionContext).components;
      const bookData = useContext(BookDataContext);

      const authors = bookData.metadata?.authors;

      if (!authors || !Array.isArray(authors) || authors.length === 0) {
        return <></>;
      }

      const authorNames = sortAuthors(authors.map(author => ({ ...author, role: author?.role ?? 'author' }))).map(
        (author, index) => <BookContextAuthorItem author={author} key={author.id || `author-${index}`} />,
      );

      return (
        <BookDefaultMetadataItem className={className} css={authorStyle}>
          {clipCount !== null && authorNames.length > clipBreakPoint ? (
            <>
              {joinComponents(authorNames.slice(0, clipCount), ', ')}
              {` 외 ${authorNames.length - clipCount}명`}
            </>
          ) : (
            joinComponents(authorNames.slice(0, clipBreakPoint), ', ')
          )}
        </BookDefaultMetadataItem>
      );
    };
  },
  {
    textSize: [{ value: 12 }] as ModularResponsiveOption<number>,
    clipBreakPoint: 2,
    clipCount: 1 as number | null,
  },
);
